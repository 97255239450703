@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

// .apexcharts-canvas {
//   text {
//     fill: #adb5bd !important;
//   }
// }

.input-group-append,
.input-group-prepend {
  display: flex;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.steps a.nav-link {
  background-color: #c3cef8;
  color: #122e45;
}
.steps a.nav-link .number {
  border-color: #122e45;
}
.pagination .pagination {
  margin-bottom: 0px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.authentication-bg{
  background-color: #122e45;
}
.account-pages{
  padding: 0px !important;
  margin: 8em 0px 8em !important;
}
.forget-card-body{
    position: relative;
    display: block;
  .logo-section{
    padding: 3em 5em 0em !important;
  }
  .form-horizontal{
    padding: 3em 4em 6em !important;
  }
}
.login-card-body{
    position: relative;
    display: block;
    .logo-section{
      padding: 3em 5em 0em !important;
    }
    .form-horizontal{
      padding: 3em 4em 6em !important;
    }
}
// ***************************************
//         Custom Styles sheets
// ***************************************
.user-img{
  .profile{
    object-fit: cover;
    object-position: top;
  }
}
.header-profile-user.profile{
    object-fit: cover;
    object-position: top;
}
.dashboard-icon{
  border-radius: 50%;
  background-color: #f3f5f7 !important;
}
.cardProfile {
  background-color: #fff;
  padding: 5px;
  margin: 5px;
  border-radius: 0px;
  .wrapper{
    padding: 15px;
  }
  .card-frame {
      position: relative;
      background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
          ),
          #132e45;
      border-radius: 20px;
      width: 100%;
      text-align: center;
      height: 13em;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left;
      background-position-x: 30px;
      .logo-card {
          width: 45px;
          position: absolute;
          right: 10px;
          bottom: 10px;
      }
      .nfc-icon-card{
          width: 15px;
          position: absolute;
          right: 15px;
          top: 10px;
      }
      .profile-img {
          position: relative;
          width: 100%;
          img {
              width: 90px;
              height: 90px;
              border: 1.5px solid #fff;
              border-radius: 50%;
              padding: 3px;
              object-fit: cover;
              object-position: top;
          }
      }
      .name-title {
          color: #fff;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
              font-size: 12px;
              color: #fff;
              margin-top: 5px;
              margin-bottom: 5px;
          }

          h5 {
              font-size: 10px;
              color: #fff;
              margin-top: 5px;
              margin-bottom: 5px;
          }
      }
      .tag-avatar {
        cursor: pointer;
        color: #354C60;

        &:hover {
          color: white;
          transition: opacity 1s ease-in;
          -moz-transition: opacity 1s ease-in;
          -webkit-transition: opacity 1s ease-in;
        }
      }
  }
  .card-content {
    position: relative;
    padding: 1em 0px 0px;
    .saveButton {
        margin-bottom: 1em;
        text-align: center ;
        .icon-txt {
            width: 20px;
            margin-left: 10px;
        }
        .btn {
            background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #4caf50 0%, #009688 100%);
            border-radius: 15px;
            color: #fff;
            padding: 10px 1em;
            font-size: 14px;
        }
    }
  }
  .cardFooter {
    position: relative;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
        ),
        #132e45;
    display: block;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 1em;
      p{
          font-size: 10px;
          color: #fff;
          text-align: center;
          
      }
  }
  .btn-custom-pay-btn {
    color: #122e45;
    border-radius: 30px;
    font-size: 16px;
    border: 1.2px solid #122e45;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 1em 58px;
  }
  .btn-custom-pay-btn:hover {
      color: #ffffff;
      background-color: #122e45;
      border: 1.2px solid #122e45;
  }
  .topicon{
      font-size: 2em;
      margin-right:.5em;
  }
  ul.detailsList > li > p:nth-child(1) {
      font-size: 11px;
      color: #122e45;
      margin-bottom: 5px;
  }
  ul.detailsList > li > p:nth-child(2) {
      font-size: 14px;
      padding: 2.5px 0;
      color:#000000;
      font-weight: bold;
  }
ul.detailsList > li > p > a{
    font-size: 14px;
    padding: 5px 0;
    color:#122e45;
    font-weight: bold;
}
ul.detailsList > li > p > a:focus, ul.detailsList > li > p > a:hover {
    font-size: 14px;
    padding: 5px 0;
    color:#122e45;
    font-weight: bold;
}
ul.detailsList > li {
    padding: 10px 0px 5px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}
}

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 30px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
}

.addBtn {
  @extend .iconBtn;
  border: 1px solid #6ed856;
  color: #6ed856;
  min-width: 117px;
  height: 25px
}

.minusBtn {
  @extend .iconBtn;
  border: 1px solid #d92923;
  color: #e14f49;
}

select {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label{
  margin: 0px 0px 0px 10px;
  line-height: 30px;
}

ul.detailsList{
  list-style: none;
  padding: 0px;
}